/**
 * Created by STOK-Comp3 on 02/0/2018.
 */
var Partners = {};

(function ( $ ) {
	var target = $( '.js-tab' );

	Partners.init = function () {
		target.on( 'click keydown', function () {
			keydownClick();

			var targetData = $( this ).data( 'target' );
			var parentTab = $( '.partner-tab' );

			parentTab.children( '.btn' ).removeClass( 'btn-dark' );
			parentTab.children( '.btn' ).removeClass( 'btn-default' );
			parentTab.children( '.btn' ).addClass( 'btn-default' );

			$( this ).removeClass( 'btn-default' );
			$( this ).addClass( 'btn-dark' );

			$( '.partner-content' ).children( 'ul' ).addClass( 'hide' );
			$( '#js-tab-' + targetData ).removeClass( 'hide' );
		} );
	};

	if ( target.length > 0 ) {
		$( Partners.init() );
	}
})( jQuery );
