var gridJS = {};

( function($){

	function onResize(){

		var grid = 	$( '.js-grid' );

		if( Viewport.documentWidth().width <= 991 ){
			var thumb = $( '.grid-thumb-list' ).outerHeight();
			grid.css({ 'height':thumb * 2 });
			$( '.grid-thumb-list-last' ).css({ 'top':thumb });
		}else{
			grid.css({ 'height':'auto' });
			$( '.grid-thumb-list-last' ).css({ 'top': '0' });
		}
	}

	gridJS.scale = function(){
		onResize();
	};

	$( window ).on( 'resize', function(){
		onResize();
	});

	gridJS.scale();

})( jQuery);
