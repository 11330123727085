(function ( $ ) {

	var target = $( '.is' );

    var instagram = {};
    instagram.gridApply = function() {
        target.masonry({
            // set itemSelector so .grid-sizer is not used in layout
            itemSelector: '.is-item',
            // use element for option
            columnWidth: '.is-sizer',
            percentPosition: true,
            gutter: '.is-gutter'
        });
    };

    instagram.videoPlay = function() {
        $('.js-play-vid').click(function() {
            var video =  $(this).children('iframe')[0];
            $(this).children('.video-screen').toggleClass('hide');

            console.log(video);
            video.src += "&autoplay=1";
            // if( video.paused ) {
            //     video.src += "&autoplay=1";
            // }
            // else {
            //     video.pause();
            // }
        });
    };

	
    
    $(window).bind('load', function(){
        if ( target.length ) {
            instagram.gridApply();
            instagram.videoPlay();
        }
    });
    
    $( window ).resize(function() {
		if ( target.length ) {
            instagram.gridApply();
        }
	});
})( jQuery );