/**
 * See ajax.php for ListPaginationSettings declaration
 * @type {{}}
 */
var ListingPagination = {};

(function ( $ ) {
	var scope = $( ListingPaginationSettings.scope );
	var container = $( '.js-listing-container' );
	var currentPage = ListingPaginationSettings.currentPage;
	var hasMore = true;
	var loader = $( '.loader' );
	var showAllButton = $( '.js-show-all-btn' );

	var load = function ( currentPage ) {
		$.ajax( {
			url: ajaxURL,
			dataType: 'json',
			data: {
				action: 'get_posts',
				page: currentPage,
				type: ListingPaginationSettings.type,
				term: ListingPaginationSettings.term,
				taxonomy: ListingPaginationSettings.taxonomy
			},
			beforeSend: function () {
				loader.show();
				showAllButton.hide();
				showAllButton.parents( '.show-more-btn' ).removeClass( 'visible-xs' ).hide();
			},
			success: function ( result ) {
				container.append( result.html );
				hasMore = result.hasMore;

				if ( hasMore ) {
					showAllButton.show();
					showAllButton.parents( '.show-more-btn' ).addClass( 'visible-xs' ).show();
				} else {
					showAllButton.hide();
					showAllButton.parents( '.card-holder' ).removeClass( 'has-button' ).removeClass( 'js-show-more' );
					showAllButton.parents( '.show-more-btn' ).remove();
				}

				loader.hide();
			}
		} );
	};

	showAllButton.click( function () {
		if ( hasMore ) {
			load( ++currentPage );
		}
	} );

	ListingPagination.init = function () {
		if ( ListingPaginationSettings.fireOnLoad ) {
			load( currentPage );
		}
	};

	if ( $( scope ).length ) {
		$( ListingPagination.init );
	}
})( jQuery );