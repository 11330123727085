/**
 * Created by STOK-Comp3 on 02/0/2018.
 */
var toggleText = {};

( function ( $ ) {
	var target = $( '.js-show-more-btn' );

	toggleText.heightDetect = function () {
		if ( $( ".navbar-toggle" ).is( ":visible" ) ) {
			$( '.js-show-more' ).each( function () {
				var showMoreHeight = $( this ).children( '.show-more' ).data( 'smheight' );
				$( this ).children( '.show-more' ).height( showMoreHeight );
			} );
		}
		else {
			$( '.js-show-more' ).children( '.show-more' ).removeAttr( 'style' );
		}
	};

	toggleText.init = function () {

		target.click( function () {
			$( this ).toggleClass( 'icon-arrow-up icon-arrow-down' );
			$( this ).parents( '.show-more-btn' ).toggleClass( 'shown' );
			$( this ).parents( '.js-show-more' ).children( '.show-more' ).toggleClass( 'show-more-active' );
		} );
	};

	if ( target.length > 0 ) {
		$( toggleText.init() );
		$( toggleText.heightDetect() );
		$( window ).resize( function () {
			$( toggleText.heightDetect() );
		} );
	}
} )( jQuery );