var toggleJS = {};

( function($){

	toggleJS.Select = function(){
		$( '.js-toggle' ).on( 'click keydown', function(e){
			keydownClick();
			$( this ).find( 'ul' ).stop().slideToggle();
			e.stopPropagation();
		});

		$( 'body' ).on( 'click keydown',function(e){
			keydownClick();
			$( '.js-toggle ul' ).slideUp();
		});

	};

	toggleJS.viewAll = function() {
		$( '.js-view-all' ).on( 'click keydown', function(e){
			keydownClick();
			e.preventDefault();
			$(this).parents( '.js-view-container' ).find('.hidden').removeClass('hidden');
			$(this).parents( '.js-view-container' ).find('.js-remove').remove();
	   });
	};

	toggleJS.hoverCard = function () {
		$( document ).on( 'hover', '.card a', function () {
				if ( !$( this ).hasClass( 'card-category' ) ) {
					$( this ).parents( '.card' ).find( 'hr' ).toggleClass( "highlight" );
				}
			}
		);
	};


	toggleJS.viewAll();
	toggleJS.Select();
	toggleJS.hoverCard();

})(jQuery);
