/**
 * Created by STOK-Comp3 on 02/02/2018.
 */
var Video = {};

function onYouTubeIframeAPIReady() {
	new YT.Player( Video.iframe[0].id, {
		events: {
			'onStateChange': onPlayerStateChange
		}
	} );
}

function onPlayerStateChange( event ) {
	if ( event.data == YT.PlayerState.PLAYING ) {
		Video.elementsToHide.remove();
		Video.iframe.css( 'opacity', 1 );
		Video.iframe.parents( '.js-play-video' ).css( 'background-image', '' );
	}
}

( function ( $ ) {
	Video.menuVisible = function () {
		var bannerHeight = $( '.hero-large' ).outerHeight();
		var computerHeight = Viewport.documentWidth().height - 160;

		if ( bannerHeight > Viewport.documentWidth().height ) {
			$( '.hero-large' ).css( {
				'height': computerHeight,
				'padding-top': 0
			} );
		}
		else {
			$( '.hero-large' ).css( {
				'height': '',
				'padding-top': ''
			} );
		}
	};

	Video.init = function () {
		Video.iframe = $( 'iframe#videoIframe' );
		Video.tag = $( 'video#videoTag' );
		Video.elementsToHide = $( '.js-hide-on-play' );

		if ( Video.iframe.length ) {
			if ( !Video.iframe.data( 'autoplay' ) || Mobile.isMobile() ) {
				// Make sure video is muted (Youtube,Vimeo) and not autoplaying
				Video.iframe.prop( 'src', Video.iframe.prop( 'src' ).replace( '&muted=1', '' ) );
				Video.iframe.prop( 'src', Video.iframe.prop( 'src' ).replace( '&mute=1', '' ) );
				Video.iframe.prop( 'src', Video.iframe.prop( 'src' ).replace( '&autoplay=1', '' ) );

				// Make sure autoplay is false (SoundCloud)
				Video.iframe.prop( 'src', Video.iframe.prop( 'src' ).replace( 'auto_play=true', 'auto_play=false' ) );

				// Include script from video provider
				var source = Video.iframe.data( 'source' );

				if ( source === 'vimeo' ) {
					$.getScript( 'https://player.vimeo.com/api/player.js', function () {
						var player = new Vimeo.Player( Video.iframe[0] );

						player.on( 'play', function () {
							Video.elementsToHide.remove();
							Video.iframe.css( 'opacity', 1 );
							Video.iframe.parents( '.js-play-video' ).css( 'background-image', '' );
						} );
					} );
				} else if ( source === 'youtube' ) {
					$.getScript( 'https://www.youtube.com/iframe_api' );
				}
			} else {
				Video.elementsToHide.remove();
				Video.iframe.css( 'opacity', 1 );
				Video.iframe.parents( '.js-play-video' ).css( 'background-image', '' );
			}
		}

		if ( Video.tag.length ) {
			if ( Video.tag.prop( 'autoplay' ) ) {
				// Put video to the front
				Video.tag.parent().css( 'z-index', 2 );
			} else {
				// Play video upon clicking the play button
				Video.tag.closest( '.video-iframe' ).siblings( '.icon-play' ).click( function () {
					videoTag[0].play();
					Video.elementsToHide.remove();
					Video.tag.parents( '.js-play-video' ).css( 'background-image', '' );
					$( this ).remove();
				} );
			}
		}
	};

	$( function () {
		Video.init();
		Video.menuVisible();
	} );

	$( window ).on( 'resize', function () {
		Video.menuVisible();
	} );

} )( jQuery );