/**
 * Created on 02/05/2018.
 */
var Viewport = {};

Viewport.documentWidth = function(){
 var e = window, a = 'inner';
  if (!('innerWidth' in window )) {
      a = 'client';
      e = document.documentElement || document.body;
  }
  return { width : e[ a+'Width' ] , height : e[ a+'Height' ] };
};


(function ( $ ) {
	var Menu = {};

	Menu.hasChildren = function () {
		if ( $( ".navbar-toggle" ).is( ":visible" ) ) {
			if ( !$( ".tgcaret" ).length ) {
				$( '.menu-item-has-children' ).append( "<button type='button' class='tgcaret'>+</button>" );
				Menu.hasChildrenClick();
			}
		}
		else {
			$( ".tgcaret" ).remove();
			$( ".tgcaret" ).text( "+" );
			$( ".sub-menu" ).removeAttr( 'style' );
		}
	};

	Menu.homeScroll = function() {
		if ( !$( ".navbar-toggle" ).is( ":visible" ) && $( '.navbar-front' ).length ) {	
			if( $(window).scrollTop() >= $(".hero-large").outerHeight() ) {
				$( '.navbar' ).addClass('navbar-fixed-top');
				$( '.header-nav' ).addClass('is-fixed');
			}
			else {
				$( '.navbar' ).removeClass('navbar-fixed-top');
				$( '.header-nav' ).removeClass('is-fixed');
			}
		}
		else {
			if( $( '.navbar-front' ).length ) {
				$( '.navbar' ).removeClass('navbar-fixed-top');
				$( '.header-nav' ).removeClass('is-fixed');
			}
		}
	};

	Menu.hasChildrenClick = function () {
		$( '.menu-item-has-children > .tgcaret' ).click( function ( e ) {
			if ( $( ".navbar-toggle" ).is( ":visible" ) ) {
				e.preventDefault();
				var text = $( this ).text();
				$( this ).text( text === "+" ? "-" : "+" );
				$( this ).parent( ".menu-item-has-children" ).children( ".sub-menu" ).slideToggle( "slow" );
			}
		} );
	};


	Menu.hoverTablet =  function () {
		$('html, body').click(function() {
			$( '.navbar-nav'  ).removeClass( 'sub-menu-active' );
			$( '.menu-item-has-children' ).removeClass( 'sub-menu-active' );
			$( '.menu-item-has-children' ).removeClass( 'clickable' );
		});

		$(document).on('touchstart', function() {
			documentClick = true;
		});

		$(document).on('touchmove', function() {
			documentClick = false;
		});

		$(document).on('click touchend', function(event) {
			if (event.type === "click") {
				documentClick = true;
			}
			if (documentClick) {
				$( '.menu-item-has-children' ).removeClass( 'sub-menu-active' );
			}
		 });

		$( '.navbar-nav .menu-item-has-children' ).click( function ( e ) {
			var isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
			if(!$( ".navbar-toggle" ).is( ":visible" ) && isMobileDevice) {
				if(!$(this).parent().hasClass( 'sub-menu-active' ) || !$(this).hasClass('clickable')) {
					e.preventDefault();
					e.stopPropagation();
					if($(this).parent().hasClass('navbar-nav')) {
						$( '.menu-item-has-children' ).removeClass( 'clickable' );
					}
					$( '.navbar-nav .menu-item-has-children' ).removeClass( 'sub-menu-active' );
					$(this).parent().addClass( 'sub-menu-active' );
					$(this).addClass('clickable');
				}
			}
		});
		
	};
	 
	Menu.hasChildren();
	Menu.hoverTablet();
	
	$( window ).resize( function () {
		Menu.hasChildren();
		Menu.homeScroll();
	} );

	$(window).bind('scroll', function() {
		Menu.homeScroll();
	});

	$( window ).ready( function(){
		var currentScroll = 0;
		$( '.navbar-collapse' ).on( 'shown.bs.collapse', function(){
			currentScroll = $( window ).scrollTop();
			$( 'body' ).addClass( 'lock-position' );
		}).on( 'hide.bs.collapse', function(){
			$( 'body' ).removeClass( 'lock-position' );
			$( window ).scrollTop( currentScroll );
		});
	});

})( jQuery );