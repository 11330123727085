var BackgroundImageSwitcher = {};

(function ( $ ) {

	var targets = $( '.js-background-image' );

	var updateBackground = function () {
		$.each( targets, function ( index, value ) {

			var _this = $( value );
			var background;

			if ( Viewport.documentWidth().width < 767 ) {
				background = _this.data( 'mobileBackground' );
			} else {
				background = _this.data( 'desktopBackground' );
			}

			if ( !background ) {
				return;
			}

			_this.css( { 'background-image': 'url(' + background + ')' } );
		} );
	};

	BackgroundImageSwitcher.init = function () {
		updateBackground();

		$( window ).on( 'resize', function () {
			updateBackground();
		} );
	};

	if ( targets.length > 0 ) {
		$( BackgroundImageSwitcher.init );
	}

	var IeCompatible = {};

	IeCompatible.imageBg = function () {
		var trident = !!navigator.userAgent.match(/Trident\/7.0/);
		var net = !!navigator.userAgent.match(/.NET4.0E/);
		var IE11 = trident && net;
		var isSamsungBrowser = navigator.userAgent.match(/SamsungBrowser/i);

		if ( window.ActiveXObject || "ActiveXObject" in window || IE11 || isSamsungBrowser ) {
			$( '.center-image' ).each( function () {
				var $container = $( this ),
					imgUrls = $container.find( 'img' ).prop( 'src' );
				if ( imgUrls ) {
					$container
						.css( 'backgroundImage', 'url(' + imgUrls + ')' )
						.addClass( 'compat-ie' );
				}
			} );
		}
	};

	IeCompatible.imageBg();

})( jQuery );
